import React from 'react';
import { KfAnchorLink } from '@klickinc/kf-react-components';
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import './styles.scss';

const SignupError = () => {
	return (
		<Layout>
			<Seo title='Sorry! Something Went Wrong | EMFLAZA® (deflazacort)'/>
			<div className='page--sign-up-error'>
				<Hero
					heroImage='hero-signup-error.png'
					heroImageMobile='hero-signup-error-mobile.png'
					title='Sorry!'
					useBreadcrumbs={false}
				></Hero>
				<section className='mt-6 mb-24 md:mb-32 md:mt-14'>
					<div className='container'>
						<p>It appears something has gone wrong on our end. <br className='hidden md:block' />Please try again later. We apologize for the inconvenience.</p>
						<div className='mt-10 text-center md:text-left md:mt-8'>
							<KfAnchorLink url='/getting-started/prescription-start-form' linkClass='capitalize btn'>Learn more about prescribing</KfAnchorLink>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default SignupError;
